<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Inspection Plan'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <vue-suggestion
                    id="equipment_id"
                    :items="equipment"
                    v-model="ItemEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeEquipment"
                    @selected="chooseEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="part_label"
                  label="Part :"
                  label-for="part_id"
                >
                  <treeselect
                    size="sm"
                    id="part_id"
                    v-model="form.part_id"
                    :clearable="true"
                    :multiple="true"
                    :async="true"
                    valueFormat="label"
                    :load-options="loadOptionsPart"
                  />
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="metode_inspeksi_label"
                  label="Metode Inspeksi:"
                  label-for="metode_inspeksi"
                >
                  <treeselect
                    size="sm"
                    id="metode_inspeksi"
                    v-model="form.metode_inspeksi"
                    :options="method"
                    :clearable="true"
                    :multiple="true"
                  />
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="damage_mechanism_label"
                  label="Mekanisme Kerusakan :"
                  label-for="damage_mechanism"
                >
                  <vue-suggestion
                    id="damage_mechanism"
                    :items="damage_mechanism"
                    v-model="itemDamageMechanism"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChange"
                    @selected="itemSelected"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  id="rencana_inspeksi_label"
                  label="Rencana Inspeksi :"
                  label-for="rencana_inspeksi"
                  v-slot="{ ariaDescribedby }"
                  description="Bisa pilih lebih dari satu"
                >
                  <b-form-checkbox-group
                    id="rencana_inspeksi"
                    v-model="form.rencana_inspeksi"
                    :options="optionsRencanaInspeksi"
                    :aria-describedby="ariaDescribedby"
                    name="rencana_inspeksi"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group label="Program" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="program"
                    v-model="form.program"
                    :aria-describedby="ariaDescribedby"
                    name="program"
                  >
                    <b-form-radio value="ON STREAM">ON STREAM</b-form-radio>
                    <b-form-radio value="TURN AROUND">TURN AROUND</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-button variant="outline-dark" :disabled="loading" @click="$router.go(-1)">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'

import itemTemplate from '@/view/pages/item-template.vue'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  mixins: [validationMixin],
  name: 'addperalataninspeksi',
  data() {
    return {
      optionsRencanaInspeksi: [
        { text: 'Jan', value: 'Jan' },
        { text: 'Feb', value: 'Feb' },
        { text: 'Mar', value: 'Mar' },
        { text: 'Apr', value: 'Apr' },
        { text: 'May', value: 'May' },
        { text: 'Jun', value: 'Jun' },
        { text: 'Jul', value: 'Jul' },
        { text: 'Aug', value: 'Aug' },
        { text: 'Sep', value: 'Sep' },
        { text: 'Oct', value: 'Oct' },
        { text: 'Nov', value: 'Nov' },
        { text: 'Dec', value: 'Dec' },
      ],

      form: {
        equipment_id: null,
        part_id: [],
        damage_mechanism: null,
        metode_inspeksi: [],
        rencana_inspeksi: [],
        program: null,
        // realisasi_inspeksi: null,
        // ditunda: null,
        // alasan_ditunda: null,
      },
      equipment: [],
      part: [],
      damage_mechanism: [],
      method: [
        { id: 'Visual', label: 'Visual' },
        { id: 'Ultrasonic Thickness', label: 'Ultrasonic Thickness' },
        { id: 'Radiography', label: 'Radiography' },
        { id: 'Ultrasonic', label: 'Ultrasonic' },
        { id: 'Liquid Penetrant', label: 'Liquid Penetrant' },
        { id: 'Magnetic Particle', label: 'Magnetic Particle' },
        { id: 'Eddy Current', label: 'Eddy Current' },
        { id: 'Leak Test', label: 'Leak Test' },
        {
          id: 'Hydrostatic Pressure Test',
          label: 'Hydrostatic Pressure Test',
        },
        { id: 'Thermography', label: 'Thermography' },
        { id: 'Hardness Test', label: 'Hardness Test' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      ItemEquipment: null,
      ItemPart: null,
      itemDamageMechanism: null,
      itemTemplate,

      show: true,
      isBusy: false,
      loading: false,
    }
  },
  validations: {
    form: {
      damage_mechanism: {
        id: {
          required,
          numeric,
        },
      },
    },
  },
  components: {
    KTCodePreview,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Inspection Plan', route: '/inspectionplan' },
      { title: 'Create' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    loadOptionsPart({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/master/part?page=1&nama_part=' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var part = data.data.data
                self.part.splice(0, self.part.length)

                for (let i = 0; i < part.length; i++) {
                  self.part.push({
                    id: part[i].id_part,
                    label: part[i].nama_part,
                  })
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          setTimeout(() => {
            callback(null, self.part)
          }, 500)
        })
      }
    },

    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this

      self.loading = true
      const equipment_id = self.form.equipment_id
      const part_id = Object.keys(self.form.part_id).map(key => self.form.part_id[key].id)

      const damage_mechanism = self.form.damage_mechanism.id
      const metode_inspeksi = self.form.metode_inspeksi
      const rencana_inspeksi = self.form.rencana_inspeksi
      const program = self.form.program
      const tahun = self.$route.params.tahun
      // const ditunda = self.form.ditunda;
      // const alasan_ditunda = self.form.alasan_ditunda;
      // console.log('equipment id : ' + equipment_id)
      // console.log('part id : ' + part_id)
      // console.log('damage mechanism : ' + damage_mechanism)
      // console.log('metode inspeksi : ' + metode_inspeksi)
      // console.log('rencana inspeksi : ' + rencana_inspeksi)
      // console.log('program : ' + program)
      // console.log('tahun : ' + tahun)

      ApiService.post('/inspectionplan/store', {
        equipment_id: equipment_id,
        part_id: part_id,
        damage_mechanism: damage_mechanism,
        metode_inspeksi: metode_inspeksi,
        rencana_inspeksi: rencana_inspeksi,
        program: program,
        tahun: tahun,
        // ditunda: ditunda,
        // alasan_ditunda: alasan_ditunda,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.go(-1)
          } else {
            self.loading = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
    },
    itemSelectedPart: function(value) {
      this.form.part_id = value.id
    },
    inputChangePart(text) {
      // your search method
      var self = this
      ApiService.get('/master/part?page=1&nama_part=' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var part = data.data.data
            self.part.splice(0, self.part.length)

            for (let i = 0; i < part.length; i++) {
              self.part.push({
                id: part[i].id_part,
                name: part[i].nama_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      // this.modalForm.part = text;
    },
    inputChange(text) {
      // your search method
      var self = this
      ApiService.get('/master/inspectionplant/showdamagemechanism/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var damage_mechanism = data.data
            self.damage_mechanism.splice(0, self.damage_mechanism.length)

            for (let i = 0; i < damage_mechanism.length; i++) {
              self.damage_mechanism.push({
                id: damage_mechanism[i].id_damage_mechanism,
                name: damage_mechanism[i].nama_damage_mechanism,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.damage_mechanism = text
    },
    itemSelected(item) {
      this.form.damage_mechanism = item
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
